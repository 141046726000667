/*
 * Static (eager loaded) Routes
 */

// import the components to route to
// import Home from "../views/Public/DEWC/LoginPage.vue";

// public user account routes
import UserLockout from "../views/Public/LockoutPage.vue";
import RegisterPassword from "../views/Public/RegisterPasswordPage.vue";
import RegisterTfa from "../views/Public/RegisterTFAPage.vue";
import LoginPage from "../views/Public/DEWC/LoginPage.vue";

// Apps
import AppHome from "../views/Areas/App/AppsHomePage.vue";

import { clientRoutes } from "../constants";

// Applications client-side route definitions

/*
 *  Route level code splitting
 */
// component: () => import(/* webpackChunkName: 'chunkName' */ 'pathToComponent')

const routes = [
  {
    path: clientRoutes.home,
    component: AppHome,
    meta: { requiresAuth: true, role: ["User"], redirect: clientRoutes.notAuthorised },
  },

  { path: clientRoutes.lockout, component: UserLockout },
  { path: clientRoutes.registerPassword, component: RegisterPassword },
  { path: clientRoutes.registerTfa, component: RegisterTfa },
  {
    path: clientRoutes.resetPassword,
    component: () => import(/* webpackChunkName: 'resetPassword' */ "../views/Public/ResetPasswordPage.vue"),
  },
  {
    path: clientRoutes.forgotLogin,
    component: () => import(/* webpackChunkName: 'forgotLogin' */ "../views/Public/ForgotLoginPage.vue"),
  },
  {
    path: clientRoutes.register,
    component: () => import(/* webpackChunkName: 'registerPage' */ "../views/Public/RegisterPage.vue"),
  },

  { path: clientRoutes.notAuthorised, component: LoginPage },

  // User
  {
    path: clientRoutes.apps.account.manage,
    component: () => import(
      /* webpackChunkName: 'accountHome' */ "../views/Areas/App/Account/AccountHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["User"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Your Profile" },
      ],
    },
  },

  // Admin Apps
  {
    path: `${clientRoutes.apps.admin.userManager}/:roleid`,
    component: () => import(
      /* webpackChunkName: 'userManager' */ "../views/Areas/App/UserManager/UserManagerHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Admin", "UserManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "User Management" },
      ],
    },
  },
  {
    path: `${clientRoutes.apps.admin.registerManager}`,
    component: () => import(
      /* webpackChunkName: 'registerManager' */ "../views/Areas/App/UserManager/RegisterEmployeePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Admin", "UserManager", "ICTSupport"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Employee Registration" },
      ],
    },
  },

  {
    path: `${clientRoutes.apps.admin.employmentManager}/:id`,
    component: () => import(
      /* webpackChunkName: 'employmentManager' */ "../views/Areas/App/UserManager/EmploymentManagerHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Admin", "UserManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        {
          text: "User Management",
          to: { path: `${clientRoutes.apps.admin.userManager}/Employee` },
        },
        { text: "Employment Management" },
      ],
    },
  },
  {
    path: clientRoutes.apps.admin.calendarManager,
    component: () => import(
      /* webpackChunkName: 'calendarManager' */ "../views/Areas/App/CalendarManager/CalendarManagerHomePage.vue"
    ),
    meta: { requiresAuth: true, role: ["Admin"], redirect: clientRoutes.notAuthorised },
  },
  {
    path: clientRoutes.apps.admin.roleManager,
    component: () => import(
      /* webpackChunkName: 'roleManager' */ "../views/Areas/App/RoleManager/RoleManagerHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Admin"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Role Management" },
      ],
    },
  },

  // employee Apps
  {
    path: clientRoutes.apps.employee.timesheet,
    component: () => import(
      /* webpackChunkName: 'timesheet' */ "../views/Areas/App/Timesheet/TimesheetHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Employee"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Timesheets" },
      ],
    },
  },
  {
    path: clientRoutes.apps.employee.timesheetEntry,
    component: () => import(
      /* webpackChunkName: 'timesheetEntry' */ "../views/Areas/App/Timesheet/TimesheetBrowse.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Employee"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Timesheets", to: { path: clientRoutes.apps.employee.timesheet } },
        { text: "Timesheet" },
      ],
    },
  },

  {
    path: clientRoutes.apps.documentLibrary.library,
    component: () => import(
      /* webpackChunkName: 'documentLibrary' */ "../views/Areas/App/DocumentLibrary/DocumentLibraryPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Employee"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Document Library" },
      ],
    },
  },
  {
    path: clientRoutes.apps.documentLibrary.manager,
    component: () => import(
      /* webpackChunkName: 'documentLibraryManager' */ "../views/Areas/App/DocumentLibrary/DocumentLibraryManagerPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BackOffice", "BusinessManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Document Library Manager" },
      ],
    },
  },
  {
    path: `${clientRoutes.apps.documentLibrary.manager}/:libraryId/:fileId`,
    component: () => import(
      /* webpackChunkName: 'documentLibraryFileAckList' */ "../views/Areas/App/DocumentLibrary/DocumentLibraryFileAckListPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BackOffice", "BusinessManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        {
          text: "Document Library Manager",
          to: { path: clientRoutes.apps.documentLibrary.manager },
        },
        { text: "Document Library Acknowledgements" },
      ],
    },
  },

  // BM apps

  {
    path: clientRoutes.apps.bm.payRoll,
    component: () => import(/* webpackChunkName: 'payRoll' */ "../views/Areas/App/PayRoll/PayRollHomePage.vue"),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "BackOffice"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Pay Processing" },
      ],
    },
  },
  {
    path: clientRoutes.apps.bm.payRollProcessing,
    component: () => import(
      /* webpackChunkName: 'payRollProcessing' */ "../views/Areas/App/PayRoll/PayRollProcessingPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "BackOffice"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", active: true },
        { text: "Timesheet List", active: true },
        { text: "Pay Processing", active: true },
      ],
    },
  },
  {
    path: clientRoutes.apps.bm.teamLeadTimesheet,
    component: () => import(
      /* webpackChunkName: 'teamleadTimesheet' */ "../views/Areas/App/PayRoll/TeamLeadTimesheetHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkProjectLead"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Timesheet List" },
      ],
    },
  },
  {
    path: clientRoutes.apps.bm.teamLeadTimesheetProcessing,
    component: () => import(
      /* webpackChunkName: 'teamleadTimesheetProcessing' */ "../views/Areas/App/PayRoll/TeamLeadTimesheetProcessingPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkProjectLead"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Timesheet List", to: { path: clientRoutes.apps.bm.teamLeadTimesheet } },
        { text: "Timesheet Processing" },
      ],
    },
  },
  {
    path: clientRoutes.apps.bm.pmTimesheetProcessing,
    component: () => import(
      /* webpackChunkName: 'pmTimesheetProcessing' */ "../views/Areas/App/PayRoll/ProgramManagerTimesheetProcessingPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "ProgramManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        {
          text: 'User Portal',
          to: { path: clientRoutes.apps.home },
        },
        {
          text: 'Program Management',
          to: { path: clientRoutes.apps.program.home },
        },
        { text: "Timesheet Processing" },
      ],
    },
  },
  {
    path: clientRoutes.apps.bm.payRollInvoicing,
    component: () => import(
      /* webpackChunkName: 'payRollInvoicing' */ "../views/Areas/App/PayRoll/PayRollInvoicingPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager"],
      redirect: clientRoutes.notAuthorised,
    },
  },

  // Work App
  {
    path: clientRoutes.apps.work.home,
    component: () => import(/* webpackChunkName: 'work' */ "../views/Areas/App/Work/WorkHomePage.vue"),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Work List" },
      ],
    },
  },

  {
    path: clientRoutes.apps.work.create,
    component: () => import(
      /* webpackChunkName: 'workManagerCreate' */ "../views/Areas/App/Work/WorkCreatePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Work List", to: { path: clientRoutes.apps.work.home } },
        { text: "Create Work" },
      ],
    },
  },

  {
    path: clientRoutes.apps.work.manager,
    component: () => import(/* webpackChunkName: 'workManager' */ "../views/Areas/App/Work/WorkManagerPage.vue"),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", active: true },
        { text: "Work List", active: true },
        { text: "Work Manager", active: true },
      ],
    },
  },
  {
    path: clientRoutes.apps.work.forecasting,
    component: () => import(
      /* webpackChunkName: 'forecastingPage' */ "../views/Areas/App/Forecasting/ForecastingPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "Employee"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        {
          text: "User Portal",
          to: { path: clientRoutes.apps.home },
        },
        { text: "Work Forecasting", active: true },
      ],
    },
  },
  {
    path: clientRoutes.apps.program.home,
    component: () => import(/* webpackChunkName: 'programPage' */ '../views/Areas/App/Program/ProgramPage.vue'),
    meta: {
      requiresAuth: true,
      role: ['BusinessManager', 'ProgramManager'],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        {
          text: "User Portal",
          to: { path: clientRoutes.apps.home },
        },
        { text: 'Program Management', active: true },
      ],
    },
  },
  {
    path: clientRoutes.apps.program.create,
    component: () => import(/* webpackChunkName: 'programCreate' */ '../views/Areas/App/Program/ProgramCreatePage.vue'),
    meta: {
      requiresAuth: true,
      role: ['BusinessManager', 'ProgramManager'],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        {
          text: 'User Portal',
          to: { path: clientRoutes.apps.home },
        },
        {
          text: 'Program Management',
          to: { path: clientRoutes.apps.program.home },
        },
        { text: 'Program Create', active: true },
      ],
    },
  },
  {
    path: clientRoutes.apps.program.details,
    component: () => import(/* webpackChunkName: 'programDetails' */ '../views/Areas/App/Program/ProgramDetailsPage.vue'),
    props: true,
    meta: {
      requiresAuth: true,
      role: ['BusinessManager', 'ProgramManager'],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        {
          text: 'User Portal',
          to: { path: clientRoutes.apps.home },
        },
        {
          text: 'Program Management',
          to: { path: clientRoutes.apps.program.home },
        },
        { text: 'Program Details', active: true },
      ],
    },
  },
  // Client & POC manager
  {
    path: clientRoutes.apps.clients.manager,
    component: () => import(/* webpackChunkName: 'clientManager' */ "../views/Areas/App/Work/Clients.vue"),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Client Manager" },
      ],
    },
  },

  // Company timesheet

  {
    path: clientRoutes.apps.workTimesheet.home,
    component: () => import(
      /* webpackChunkName: 'workTimesheet' */ "../views/Areas/App/Reports/CompanyTimesheetHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment", "WorkPortfolioDirector", "WorkProgramManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Company Timesheet Report" },
      ],
    },
  },

  // Company TCE Report

  {
    path: clientRoutes.apps.companyTCE.home,
    component: () => import(
      /* webpackChunkName: 'companyTce' */ "../views/Areas/App/Reports/CompanyTCEHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "BackOffice"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Company TCE Report" },
      ],
    },
  },

  // Work View App
  {
    path: clientRoutes.apps.workviewer.home,
    component: () => import(
      /* webpackChunkName: 'workViewer' */ "../views/Areas/App/WorkViewer/WorkViewerHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment", "WorkPortfolioDirector", "WorkProgramManager", "WorkProjectLead"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Work List" },
      ],
    },
  },
  {
    path: clientRoutes.apps.workviewer.details,
    component: () => import(
      /* webpackChunkName: 'workViewerDetails' */ "../views/Areas/App/WorkViewer/WorkViewerDetailsPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment", "WorkPortfolioDirector", "WorkProgramManager", "WorkProjectLead"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", active: true },
        { text: "Work List", active: true },
        { text: "Work Actuals Report", active: true },
      ],
    },
  },
  {
    path: clientRoutes.apps.workviewer.burnChart, // Used for whole of Work burn
    component: () => import(
      /* webpackChunkName: 'workBurnChart' */ "../views/Areas/App/WorkViewer/WorkBurnUpChart.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment", "WorkPortfolioDirector", "WorkProgramManager", "WorkProjectLead"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", active: true },
        { text: "Work List", active: true },
        { text: "Work Burnup", active: true },
      ],
    },
  },
  {
    path: clientRoutes.apps.workviewer.subsetBurnChart, // Used for Contract, Task or Resource burn
    component: () => import(
      /* webpackChunkName: 'subsetBurnChart' */ "../views/Areas/App/WorkViewer/WorkBurnUpChart.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment", "WorkPortfolioDirector", "WorkProgramManager", "WorkProjectLead"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", active: true },
        { text: "Work List", active: true },
        { text: "Work Burnup", active: true },
      ],
    },
  },

  // Workload App
  {
    path: clientRoutes.apps.workload.home,
    component: () => import(/* webpackChunkName: 'workLoad' */ "../views/Areas/App/WorkLoad/WorkLoadHomePage.vue"),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "WorkOwner", "WorkBusinessDevelopment", "WorkPortfolioDirector", "WorkProgramManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Employee Workload Viewer" },
      ],
    },
  },
  {
    path: clientRoutes.apps.workload.user,
    component: () => import(
      /* webpackChunkName: 'workLoadUser' */ "../views/Areas/App/WorkLoad/WorkLoadUserHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Employee"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Your Workload" },
      ],
    },
  },

  // Work resource budget
  {
    path: clientRoutes.apps.workResourceBudget.home,
    component: () => import(
      /* webpackChunkName: 'workResourceBudget' */ "../views/Areas/App/WorkProfile/WorkProfileHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "Admin", "WorkOwner", "WorkBusinessDevelopment", "WorkPortfolioDirector", "WorkProgramManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Work Resource Budget Report" },
      ],
    },
  },

  // Reports
  {
    path: clientRoutes.apps.reports.employee,
    component: () => import(
      /* webpackChunkName: 'employeeReport' */ "../views/Areas/App/Reports/EmployeeHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Employee"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Timesheet Reports" },
      ],
    },
  },
  {
    path: clientRoutes.apps.reports.toil,
    component: () => import(
      /* webpackChunkName: 'toilReport' */ "../views/Areas/App/Reports/ToilReportPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "BackOffice"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Toil Report" },
      ],
    },
  },
  {
    path: clientRoutes.apps.reports.teamLead,
    component: () => import(
      /* webpackChunkName: 'teamLeadReport' */ "../views/Areas/App/Reports/TeamLeadHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["WorkProjectLead", "WorkProgramManager"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Client Reports" },
      ],
    },
  },
  {
    path: clientRoutes.apps.reports.businesssupport,
    component: () => import(
      /* webpackChunkName: 'businessSupportReport' */ "../views/Areas/App/Reports/BusinessSupportHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "BackOffice"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Client Reports" },
      ],
    },
  },
  {
    path: clientRoutes.apps.reports.invoices,
    component: () => import(
      /* webpackChunkName: 'invoiceReport' */ "../views/Areas/App/Reports/InvoicesHomePage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "BackOffice"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Client Invoices" },
      ],
    },
  },
  {
    path: clientRoutes.apps.reports.mouInvoices,
    component: () => import(
      /* webpackChunkName: 'mouInvoiceReport' */ "../views/Areas/App/Reports/MouInvoice.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "BackOffice"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "MOU Invoices" },
      ],
    },
  },

  // Comms
  {
    path: clientRoutes.apps.comms.sms,
    component: () => import(
      /* webpackChunkName: 'smsMessenger' */ "../views/Areas/App/Comms/SmsMessengerPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "CommunicationsUser"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "SMS Messenger" },
      ],
    },
  },
  {
    path: clientRoutes.apps.comms.email,
    component: () => import(
      /* webpackChunkName: 'emailMessenger' */ "../views/Areas/App/Comms/EmailMessengerPage.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["BusinessManager", "CommunicationsUser"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Email Messenger" },
      ],
    },
  },

  // Master Data
  {
    path: clientRoutes.apps.bm.masterdata,
    component: () => import(
      /* webpackChunkName: 'masterData' */ "../views/Areas/App/MasterData/MasterDataHome.vue"
    ),
    meta: {
      requiresAuth: true,
      role: ["Admin"],
      redirect: clientRoutes.notAuthorised,
      breadcrumb: [
        { text: "User Portal", to: { path: clientRoutes.apps.home } },
        { text: "Master Data" },
      ],
    },
  },

  { path: "*", redirect: clientRoutes.home },
].filter((x) => x !== undefined);
export default routes;
