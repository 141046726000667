<template>
  <b-container class="page">
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onSubmit);">
      <form-input :label="login.label"
                  :name="login.name"
                  :value.sync="login.value"
                  :validations="login.validations"
                  :type="login.type" />

      <form-input :label="phoneNumber.label"
                  :name="phoneNumber.name"
                  :value.sync="phoneNumber.value"
                  :validations="phoneNumber.validations"
                  :type="phoneNumber.type" />
      <div><small>Australian Mobile Numbers Only [Example: 0400110011]</small></div>
      <b-button @click="sendCode">Send Code</b-button>

      <form-input v-if="sent"
                  :label="tfaCode.label"
                  :name="tfaCode.name"
                  :value.sync="tfaCode.value"
                  :validations="tfaCode.validations"
                  :type="tfaCode.type" />

      <b-form-group v-if="sent">
        <b-button variant="primary" type="submit"
                  :disabled="loading">Complete Registration</b-button>
      </b-form-group>
    </b-form>
  </ValidationObserver>
  </b-container>
</template>
<script>
import axios from 'axios';
import { ValidationObserver } from 'vee-validate';
import FormMethods from '@/mixins/FormMethods.vue';
import FormInput from '@/components/FormControls/FormInput.vue';

import { clientRoutes, apiRoutes, validation } from '../../constants';

export default {
  name: 'register-password-page',
  components: {
    ValidationObserver,
    FormInput,
  },
  mixins: [FormMethods],
  data() {
    return {
      login: this.formInputObject('Email', null, 'Email', '', validation.email, 'email'),
      phoneNumber: this.formInputObject('Mobile Number', null, 'phone', '', validation.mobileAu, 'tel'),
      tfaCode: this.formInputObject('Code', null, 'tfaCode', '', validation.string.detail, 'text'),
      registered: false,
      sent: false,

    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    resetData() {
      this.registered = true;
      this.login.value = '';
      this.phoneNumber.value = '';
      this.tfaCode.value = '';
    },
    sendCode() {
      this.sent = false;
      this.$refs.observer.validate().then((success) => {
        if (!success) {
          return;
        }
        axios
          .get(`${apiRoutes.account.registerTfaUrl}/${this.login.value}/${this.phoneNumber.value}`)
          .then((response) => {
            this.processSuccess(response.data);
            this.sent = true;
          })// end then
          .catch((error) => {
            this.processError(error);
          });// end catch
      });
    },
    onSubmit() {
      const payload = {
        email: this.login.value,
        phone: this.phoneNumber.value,
        code: this.tfaCode.value,
      };

      this.$store.commit('startLoading');

      axios
        .post(apiRoutes.account.registerTfaUrl, payload)
        .then((response) => {
          this.resetData();
          this.$store.commit('finishLoading');

          this.processSuccess(response);

          this.$router.push({ path: clientRoutes.notAuthorised });
          this.sent = false;
        })// end then
        .catch((error) => {
          this.processError(error);

          this.$store.commit('finishLoading');
        });// end catch
    }, // end forgotLoginSubmit
  }, // end methods
};
</script>
