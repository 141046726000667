<template>
  <form @submit.prevent="$emit('submit');" class="p-2">
    <slot><!--form inputs will go here --></slot>
  </form>
</template>
<script>

export default {
  name: 'form-base',
  methods: {
    resolveUndefined(value) {
      return (value === undefined) ? null : value;
    },
    formObject(label, id, name, validations) {
      label = this.resolveUndefined(label);
      id = this.resolveUndefined(id);
      name = this.resolveUndefined(name);
      validations = this.resolveUndefined(validations);
      return {
        label,
        id,
        name,
        validations,
      };
    },
    formSingleCheckBoxObject(label, id, name, value, validations) {
      const returnObject = this.formObject(label, id, name, validations);

      value = this.resolveUndefined(value);

      returnObject.value = (value === null) ? 'false' : value;
      return returnObject;
    },
    formListObject(label, id, name, value, validations, options) {
      const returnObject = this.formObject(label, id, name, validations);

      options = this.resolveUndefined(options);
      value = this.resolveUndefined(value);

      returnObject.value = (value === null) ? [] : value;
      returnObject.options = (options === null) ? [] : options;

      return returnObject;
    },
    formTextAreaObject(label, id, name, value, validations, rows, maxRows, placeholder) {
      const returnObject = this.formObject(label, id, name, validations);

      rows = this.resolveUndefined(rows);
      maxRows = this.resolveUndefined(maxRows);
      placeholder = this.resolveUndefined(placeholder);
      value = this.resolveUndefined(value);

      returnObject.placeholder = (placeholder === null) ? '' : placeholder;
      returnObject.value = (value === null) ? '' : value;
      returnObject.rows = (rows === null) ? 3 : rows;
      returnObject.maxRows = (maxRows === null) ? 6 : maxRows;

      return returnObject;
    },
    formInputObject(label, id, name, value, validations, type, placeholder, step) {
      const returnObject = this.formObject(label, id, name, validations);

      placeholder = this.resolveUndefined(placeholder);
      type = this.resolveUndefined(type);
      value = this.resolveUndefined(value);
      step = this.resolveUndefined(step);

      returnObject.placeholder = (placeholder === null) ? '' : placeholder;
      returnObject.type = (type === null) ? 'text' : type;
      returnObject.value = (value === null) ? '' : value;
      returnObject.step = (step === null) ? '1' : step;

      return returnObject;
    },

    dataValid(response) {
      if (response.response && response.response.statusText) {
        if (response.response.data
          && response.response.data.isError
          && response.response.data.isError !== true) {
          return { isNotification: true, msg: response.response.statusText };
        }
      }

      if (response.response && response.response.data) {
        return response.response.data;
      }

      if (response && response.data) {
        return response.data;
      }

      if (response) {
        return response;
      }

      return null;
    },
    processNotification(data, notificationType) {
      if (data) {
        if (data.isNotification && data.isNotification === true) {
          this.$toastr(notificationType, data.msg);
        }
      }
    },
    processSuccess(axios) {
      const data = this.dataValid(axios);
      if (typeof (data) === 'string') {
        const notification = { isNotification: true, msg: data };
        this.processNotification(notification, 'success');
      } else {
        this.processNotification(data, 'success');
      }
    },
    processError(axios) {
      const data = this.dataValid(axios);

      if (!data.errors) {
        if (!Array.isArray(data) && data.isNotification) {
          this.processNotification(data, 'error');
        } else if (!Array.isArray(data) && data.Error) {
          this.processNotification({ isNotification: true, msg: data.Error }, 'error');
        }
        return [];
      }

      let i = 0;

      for (i; i < data.errors.length; i += 1) {
        this.processNotification(data.errors[i], 'error');
      }

      return data.errors;
    },
    processErrorBlob(error) {
      try {
        const { data } = error.response;
        // Read blob response
        data.text().then((message) => {
          this.processError({
            isNotification: true,
            msg: message,
          });
        });
      } catch {
        this.processError({
          isNotification: true,
          msg: 'Error downloading file.',
        });
      }
    },
    /**
       * Check if the result is an ApiException from the Server.
       * If there is an error from the server return the list of
       * errors for display by the errors component
       *
       */
    processProperties(axios, data, setValue, setDisplay) {
      // test that the server has returned view model properties
      if (!(axios.data === undefined
          || axios.data.properties === null
          || axios.data.properties === undefined)) {
        // get the keys for the servers viewmodel properties
        const keys = Object.keys(axios.data.properties);
        let i = 0;
        for (i; i < keys.length; i += 1) {
          const key = keys[i];
          if (!(data[key] === null || data[key] === undefined)) {
            if (data[key].validations !== undefined) {
              data[key].validations = axios.data.properties[key].validations;
            }// end of validations assignment

            if (data[key].value !== undefined && setValue) {
              data[key].value = axios.data.properties[key].value;
            }// end of value assignment

            if (data[key].displayName !== undefined && setDisplay) {
              data[key].displayName = axios.data.properties[key].displayName;
            }// end of displayName assignment
          }// end of property assignment
        }// end of iterating through the servers viewmodel properties
      }// end of properties null check
    },
    state(errors, valid) {
      if (errors[0]) {
        return false;
      }
      if (valid) {
        return true;
      }
      return null;
    },
    formatDate(dateInput) {
      if (!dateInput) {
        return '';
      }
      return dateInput.split('T')[0];
    },
    formatDateUI(inputDate) { // Turns ISO date (yyyy-mm-dd[THH:MM:ss]) into a Australian pretty date (d/mm/yyyy)
      if (!inputDate) {
        return '';
      }
      const dateTemp = inputDate.split('T')[0];
      return `${dateTemp.split('-')[2]}/${dateTemp.split('-')[1]}/${dateTemp.split('-')[0]}`;
    },
    formatTime(inputTime) {
      if (!inputTime) {
        return '';
      }
      return `${inputTime.split(':')[0]}:${inputTime.split(':')[1]}`;
    },
    formatTimeUI(inputDate) { // Turns ISO date (yyyy-mm-dd[THH:MM:ss]) into a pretty time (HH:MM)
      if (!inputDate) {
        return '';
      }
      const inputTime = inputDate.split('T')[1];
      return `${inputTime.split(':')[0]}:${inputTime.split(':')[1]}`;
    },
    toLocalISOString(dateObj) { // does the same as toISOString but does not convert to UTC (or does same as toString but uses ISO format)
      const pad = (n) => (n < 10 ? `0${n}` : n);
      return `${dateObj.getFullYear()}-${// local year
        pad(dateObj.getMonth() + 1)}-${// local month
        pad(dateObj.getDate())}T${// local day
        pad(dateObj.getHours())}:${// local hour
        pad(dateObj.getMinutes())}`; // local minute
    },
    toLocalISOStringDiscardTime(dateObj) { // Same as toLocalISOString but discards time altogether
      const pad = (n) => (n < 10 ? `0${n}` : n);
      return `${dateObj.getFullYear()}-${// local year
        pad(dateObj.getMonth() + 1)}-${// local month
        pad(dateObj.getDate())}`; // local day, discard time
    },
  },
};
</script>
