<template>
    <div class="page">
        <h2 class="text-danger">Account Locked Out</h2>
        <p class="text-danger">This account has been locked out,
        please try again later.</p>
    </div>
</template>
<script>
export default {
  name: 'user-lockout-page',
};
</script>
