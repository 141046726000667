/*
 * Vuex Mutation Summary
 *
 * Method: this.$store.Commit()
 *
 * Mutations are the only manner for which global state change can occur.
 * They do not contain any logic and only perform assignment on Vuex variables.
 *
 * Mutations are called by Vuex commits and are synchronous in nature.
 *
 * This allows for a Mutation history to be generated to ensure a history of state changes
 * is transparent and traceable.
 *
 *
 */

import { systemConstants } from '../constants';

/** ************************************
 * Spinner Mutations
 *
/************************************** */

/**
 *
 * Method: startRouteSpinner
 * Call before a route redirect occurs to start the modal activity spinner and register on the count
 *
 * @param {any} state
 */

export const startRouteSpinner = (state) => {
  state.spinner = true;
};

/**
 *
 * Method: stopRouteSpinner
 * Stop the modal activity spinner and reset the count
 *
 * @param {any} state
 */

export const stopRouteSpinner = (state) => {
  state.spinner = false;
  state.spinnerAxios = false;
};

/**
 *
 * Method: enableAxiosSpinner
 * used to enable setting the axios spinner
 *
 * @param {any} state
 */

export const enableAxiosSpinner = (state) => {
  state.spinnerAxiosEnabled = true;
};

/**
 *
 * Method: enableAxiosSpinner
 * used to enable setting the axios spinner
 *
 * @param {any} state
 */

export const disableAxiosSpinner = (state) => {
  state.spinnerAxiosEnabled = false;
};

/**
 *
 * Method: startAxiosSpinner
 * Call before an ajax call to start the modal activity spinner and register on the count
 *
 * @param {any} state
 */

export const startAxiosSpinner = (state) => {
  if (state.spinnerAxiosEnabled) {
    state.spinnerAxios = true;
  }
};

/**
 *
 * Method: stopAxiosSpinner
 * Call after an ajax call completes to stop the modal activity spinner and reset the count
 *
 * @param {any} state
 */

export const stopAxiosSpinner = (state) => {
  state.spinnerAxios = false;
};

export const initialise = (state, payload) => {
  Object.assign(state, payload);
};

/** ************************************
 * Show Auth Login Mutations
 *
/************************************** */

export const clearLoading = (state) => {
  state.loading = false;
};

export const loginRequest = (state) => {
  state.loading = true;
};

export const tfaCodeRequest = (state) => {
  state.loading = true;
};

export const tfaVerifyRequest = (state) => {
  state.loading = true;
};

export const loginRequiresTfa = (state) => {
  state.loading = false;
};

export const loginTfaCodeSent = (state) => {
  state.loading = false;
};

export const loginSuccess = (state, payload) => {
  state.auth = payload;
  state.loading = false;
};
export const loginError = (state) => {
  state.loading = false;
};

/** ************************************
 * Register Mutations
 *
/************************************** */

export const registerRequest = (state) => {
  state.loading = true;
};
export const registerSuccess = (state) => {
  state.loading = false;
};
export const registerError = (state) => {
  state.loading = false;
};

/** ************************************
 * Register Mutations
 *
/************************************** */

export const foundURequest = (state) => {
  state.foundULoading = true;
};

export const foundUSuccess = (state, response) => {
  state.foundU = response.result;
  state.foundULoading = false;
  state.foundUNextState = response.nextState;
};

export const foundUError = (state) => {
  state.foundULoading = false;
};

/*
export const foundUError = (state) => {
  state.foundU = null;
  state.foundULoading = false;
  state.foundUNextState = systemConstants.foundUStatus.login;
};
*/

export const foundULogout = (state) => {
  state.foundU = null;
  state.foundULoading = false;
  state.foundUNextState = systemConstants.foundUStatus.login;
};

/** ************************************
 * Register Mutations
 *
/************************************** */

/**
 * on logout set auth to null
 * @param {any} state
 */

export const logout = (state) => {
  state.auth = null;
};

export const startLoading = (state) => {
  state.loading = true;
};

export const finishLoading = (state) => {
  state.loading = false;
};
