/*
 * Global constants to reduce magic numbers and strings throughout the application
 *
 * to use:
 *
 * in the script section of a component or at the top of a js file add
 * import {constName1, constName2} from 'relative path to this file';
 *
 */

import { extend } from 'vee-validate';
import {
  between, confirmed, email, max, max_value as maxValue, min, min_value as minValue, numeric, regex, required,
} from 'vee-validate/dist/rules';

const customVeeValidationRules = {
  date_format: {
    validate(value) {
      const dateRegexStr = /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/;
      const dateRegex = new RegExp(dateRegexStr);
      return dateRegex.test(value);
    },
    params: ['dateFormat'],
  },
  decimal: {
    validate: (value, { decimals = '*', separator = '.' } = {}) => {
      if (value === null || value === undefined || value === '') {
        return {
          valid: false,
        };
      }
      if (Number(decimals) === 0) {
        return {
          valid: /^-?\d*$/.test(value),
        };
      }
      const regexPartStr = decimals === '*' ? '+' : `{1,${decimals}}`;
      const decimalRegex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPartStr})?([eE]{1}[-]?\\d+)?$`);

      return {
        valid: decimalRegex.test(value),
      };
    },
    message: 'The {_field_} field must contain only decimal values',
  },
  password: {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'Password confirmation does not match',
  },
};

export function installValidationRules() {
  // Import Vee Validation rules we need: between, confirmed, email, max, max_value, min, min_value, numeric, regex, required
  extend('between', {
    ...between,
    message: 'Must be between {min} and {max}',
  });
  extend('confirmed', {
    ...confirmed,
    message: 'Confirmation does not match',
  });
  extend('email', {
    ...email,
    message: 'Must be a valid email address',
  });
  extend('max', {
    ...max,
    message: 'Value must be {length} or fewer characters long',
  });
  extend('max_value', {
    ...maxValue,
    message: 'Value must be {max} or less',
  });
  extend('min', {
    ...min,
    message: 'Value must be {length} or more characters long',
  });
  extend('min_value', {
    ...minValue,
    message: 'Value must be {min} or greater',
  });
  extend('numeric', {
    ...numeric,
    message: 'Value must be a number',
  });
  extend('regex', {
    ...regex,
    message: 'Value must match satisfy the regular expression: {regex}',
  });
  extend('required', {
    ...required,
    message: 'Value is required',
  });

  // Import Custom Validation rules we need: date_format, decimal, password
  Object.keys(customVeeValidationRules).forEach((customRule) => {
    extend(customRule, customVeeValidationRules[customRule]);
  });
}

export const systemConstants = {
  jobListTypes: {
    responsibilty: 1,
    attribute: 2,
    require: 3,
    desirable: 4,
  },
  jobAppFileType: {
    resume: 1,
    coverLetter: 2,
    additionalFile: 3,
  },
  status: {
    Draft: 101,

    Submitted: 201,
    PendingApproval: 202,
    PendingReview: 203,

    New: 301,
    Active: 302,
    Approved: 303,
    Processing: 304,

    Finalised: 401,
    Closed: 402,
    Achieved: 501,
    NotAchieved: 502,
    Successful: 503,
    Unsuccessful: 504,
    Accepted: 505,
    Declined: 506,

    Cancelled: 701,
    Deleted: 702,
  },
  result: {
    New: 301,
    Achieved: 501,
    NotAchieved: 502,
    Cancelled: 701,
  },
  foundUStatus: {
    login: 0,
    mfaType: 1,
    mfaCode: 2,
    loggedIn: 3,
  },
  employmentType: {
    fullTime: 1,
    casual: 2,
    subcontractor: 3,
    director: 4,
    salaried: 5,
    partTime: 6,
  },
};

export const apiRoutes = {
  authenication: {
    loginUrl: '/api/token/login',
    tfaSendUrl: '/api/token/send',
    tfaVerifyUrl: '/api/token/verify',
    refreshUrl: '/api/token/refresh',
    impersonateUrl: '/api/token/impersonate',
  },
  contact: '/api/contact',
  file: {
    upload: '/api/upload',
    download: '/api/download',
  },
  foundU: {
    login: '/api/foundu',
    checkLogin: '/api/foundu/checkLogin',
    setMFAType: '/api/foundu/mfatype',
    setMFA: '/api/foundu/setmfa',
    logout: '/api/foundu/logout',
    timesheet: '/api/foundu/timesheet',
  },
  news: '/api/news',
  app: {
    base: '/api/apps',
    masterData: '/api/masterData',
    clients: '/api/clients',
    timecard: '/api/timecard',
    timesheet: '/api/timesheet',
    payroll: '/api/payroll',
    job: '/api/jobs',
    work: '/api/work',
    workResourceBudget: '/api/workresourcebudget',
    workViewer: '/api/workviewer',
    workBurn: '/api/workviewer/burn',
    workload: '/api/workload',
    workForecasting: '/api/work/forecast',
    news: '/api/newsmanager',
    documentLibrary: '/api/library',
    documentLibraryManager: '/api/library/manager',
    comms: {
      base: '/api/comms',
      sms: '/api/comms/sms',
      email: '/api/comms/email',
    },
    programManagement: {
      base: '/api/programs',
    }
  },
  reports: {
    toilReport: '/api/reports/toil',
    teamLead: '/api/reports/teamlead',
    businesssupport: '/api/reports/businesssupport',
    signoffs: '/api/reports/employee/signoffs',
    timesheetEntries: '/api/reports/employee/entries',
    clientReport: '/api/reports/employee/report',
    invoiceClients: '/api/reports/invoice/clients',
    invoiceContracts: '/api/reports/invoice/contracts',
    invoiceEntries: '/api/reports/invoice/entries',
    invoiceReport: '/api/reports/invoice/report',
    mouInvoiceCompanies: '/api/reports/mouinvoice/companies',
    mouInvoiceEntries: '/api/reports/mouinvoice/entries',
    mouReport: '/api/reports/mouinvoice/report',
    companyTce: '/api/work/report/tce',
  },
  admin: {
    base: '/api/admin/',
    user: '/api/admin/user/',
    roles: '/api/admin/roles/',
    employment: '/api/admin/employment',
  },
  account: {
    userUrl: '/api/user',
    registerExternalUser: '/api/account/register/',
    registerUrl: '/api/account/register',
    registerPasswordUrl: '/api/account/register/activate',
    registerTfaUrl: '/api/account/register/activate/tfa',
    forgotEmailUrl: '/api/account/password/forgot',
    resetPasswordUrl: '/api/account/password/reset',
    changePasswordUrl: '/api/account/password/change',
    setPasswordUrl: '/api/account/password/set',
    userProfile: '/api/account/manage/user/',
    updateUrl: '/api/account/manage/user/',
    resendRegistrationUrl: '/api/account/register/resendActivation',
    changeEmailUrl: '/api/account/manage/change/email',
    changePhoneUrl: '/api/account/manage/change/phone',
    verifyEmailUrl: '/api/account/manage/verify/email',
    verifyPhoneUrl: '/api/account/manage/verify/phone',
  },
};

export const httpHeaders = {
  auth: 'Authorization',
};

export const validation = {
  passwordRelax: { required: true, max: 100 },
  passwordStrict: {
    required: true,
    min: 8,
    max: 100,
    regex: '^(?=.*[#$^+=!*()@%&])(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{8,100}$',
  },
  mobileAu: { required: true, regex: '^04[0-9]{8}' },
  date: { required: true, date_format: 'yyyy-MM-dd' },
  email: { required: true, email: true, max: 256 },
  number: { required: true, numeric: true },
  float: { required: true, decimal: true },
  weeks: { required: true, numeric: true, min_value: 1 },
  weeklyHours: {
    required: true,
    decimal: true,
    min_value: 0.1,
    max_value: 100.0,
  },
  dailyHours: {
    required: true,
    decimal: true,
    min_value: 0.0,
    max_value: 24,
  },
  breakTime: { required: true, min_value: 0 },
  string: {
    name: { required: true, max: 50 },
    default: { required: true, max: 100 },
    detail: { required: true, max: 256 },
    description: { required: true, max: 512 },
    comment: { required: true, max: 1024 },
    sms: { required: true, max: 160 },
  },
  required: { required: true },
};

export const clientRoutes = {
  home: '/',
  contact: '/contact',
  capabilities: '/capabilities/:id',
  experience: '/experience',
  about: '/about',
  privacy: '/privacy',
  terms: '/terms',
  jobs: '/jobs',
  job: '/job/:id',
  news: '/news',
  newsImages: '/Uploads/news',
  lockout: '/lockout',
  register: '/register/:type',
  checkout: '/checkout',
  registerPassword: '/registerpassword/:first/:second',
  registerTfa: '/registerTfa',
  resetPassword: '/resetpassword/:first/:second',
  forgotLogin: '/forgotlogin',
  notAuthorised: '/login',
  setPassword: '/setpassword', // protect with admin role
  changePassword: '/changepassword',
  apps: {
    home: '/app/home',
    account: {
      manage: '/app/account',
    },
    applicant: {
      jobApplication: '/app/job/:id/apply/:appId',
      jobApplicationList: '/app/job/applicant',
    },
    employee: {
      timesheet: '/app/timesheet',
      timesheetEntry: '/app/timesheet/:year/:id',
    },
    documentLibrary: {
      library: '/app/document-library',
      manager: '/app/document-library-manager',
    },
    bm: {
      masterdata: '/app/bm/masterdata',
      payRoll: '/app/payroll',
      payRollProcessing: '/app/payroll/:userId/:id/:year',
      payRollInvoicing: '/app/payroll/invoice/:id/:month/:year',
      teamLeadTimesheet: '/app/team-lead/timesheet',
      teamLeadTimesheetProcessing: '/app/team-lead/timesheet/:userId/:timesheet/:year',
      pmTimesheet: '/app/program/timesheet',
      pmTimesheetProcessing: '/app/program/timesheet/:userId/:timesheet/:year',
      jobList: '/app/job',
      jobManagement: '/app/job/:id',
      jobApplications: '/app/job/:id/applications',
    },
    clients: {
      manager: '/app/clientmanager',
    },
    work: {
      home: '/app/work',
      create: '/app/work/create',
      manager: '/app/work/:id/:companyId/:workTypeId',
      forecasting: '/app/work/forecasting',
      // program: '/app/work/program',
      // details: '/app/work/details/:slug',
    },
    program: {
      home: '/app/work/program',
      create: '/app/program/create',
      details: '/app/program/:id/:companyId',
    },
    workResourceBudget: {
      home: '/app/work-resource-budget',
    },
    workTimesheet: {
      home: '/app/company-timesheets',
    },
    companyTCE: {
      home: '/app/company-tce',
    },
    workviewer: {
      home: '/app/work-viewer',
      details: '/app/work-viewer/:id',
      burnChart: '/app/work-viewer/:id/burn/:type',
      subsetBurnChart: '/app/work-viewer/:id/burn/:type/:containerid',
    },
    workload: {
      home: '/app/workload',
      user: '/app/workload/user/:id',
    },
    admin: {
      appsManager: '/app/admin/appsmanager',
      userManager: '/app/admin/usermanager',
      registerManager: '/app/admin/register',
      employmentManager: '/app/admin/employmentmanager',
      roleManager: '/app/admin/rolemanager',
      calendarManager: '/app/admin/calendarmanager',
    },
    reports: {
      toil: '/app/reports/toil',
      employee: '/app/reports/employee',
      teamLead: '/app/reports/teamlead',
      businesssupport: '/app/reports/businesssupport',
      invoices: '/app/reports/invoices',
      mouInvoices: '/app/reports/mouinvoices',
    },
    news: {
      manager: '/app/newsmanager',
    },
    comms: {
      sms: '/app/comms/sms',
      email: '/app/comms/email',
    },
  },
};
