// Import VueJs libary installed by npm and configured by webpack
import Vue from 'vue';
// Import Vuex global state management libary installed by npm and configured by webpack
import Vuex from 'vuex';

// import all defined Vuex actions, mutation and getters for the application
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';

Vue.use(Vuex);

// define the Vuex state object and the object's initial state
// foundu states 1: Login, 2: MFA Type, 3: MFA Code, 4: logged in.

const state = {
  foundU: null,
  foundULoading: false,
  foundUNextState: 1,
  auth: null,
  loading: false,
  spinnerAxios: false,
  spinnerAxiosEnabled: true,
  spinner: false
};

/*
 * create the Vuex store for the application by adding the define actions,
 * getters, mutations and state object
 * */

const store = new Vuex.Store({
  strict: true,
  actions,
  mutations,
  getters,
  state,
});

store.subscribe((mutation, _state) => {
  localStorage.setItem('store', JSON.stringify(_state));
});

// export the Vuex store for global use
export default store;
