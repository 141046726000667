<template>
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onSubmit);" class="p-2">
    <p>Employee Two Factor Login Verification</p>
      <form-input :label="code.label"
                  :name="code.name"
                  :value.sync="code.value"
                  :validations="code.validations"
                  :type="code.type" />

      <b-form-group>
        <b-button-group>
          <b-button variant="primary" type="submit"
                    :disabled="loading">Submit Code</b-button>
          <b-button variant="light" @click="$emit('close')"
                    :disabled="loading">Cancel</b-button>
        </b-button-group>
      </b-form-group>
    </b-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import FormInput from '@/components/FormControls/FormInput.vue';
import FormMethods from '@/mixins/FormMethods.vue';
import { validation } from '../../../constants';

export default {
  name: 'tfa-verification',
  components: {
    ValidationObserver,
    FormInput,
  },
  mixins: [FormMethods],
  props: {
    login: {
      type: String,
    },
    method: {
      type: String,
    },

  },
  data() {
    return {
      code: this.formInputObject('Code', null, 'code', '', validation.required, 'text'),
      successMsg: 'Login Successful.',
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    onSubmit() {
      const payload = {
        email: this.login,
        method: this.method,
        code: this.code.value,
      };

      this.$store
        .dispatch('tfaVerify', payload)
        .then(() => {
          this.code.value = '';
          this.$toastr('success', this.successMsg);
          this.$emit('goToHome');
        })
        .catch((error) => {
          this.processError(error);
        });// end of catch
    },
  },

};

</script>
