<template>
  <div id="TOP">
    <b-overlay  :show="loading">
    <div>
      <nav class="navbar fixed-top" style="padding:0px;border-top:0px;">
        <top-nav-bar />
        <nav-bar-dewc />
      </nav>
    </div>

    <div>
      <transition name="fade" mode="in-out">
        <router-view />

      </transition>

      <page-footer-dewc />
    </div>
    </b-overlay>

  </div>
</template>
<script>

import TopNavBar from '@/components/Layout/TopNavBar.vue';
import NavBarDewc from '@/components/Layout/DEWC/NavBar.vue';
import PageFooterDewc from '@/components/Layout/DEWC/PageFooter.vue';

import { systemConstants } from './constants';

export default {
  name: 'app',
  components: {
    TopNavBar,
    NavBarDewc,
    PageFooterDewc,
  },
  data() {
    return {
      isDewc: systemConstants.DEWC,
      loginTitle: 'Login',
      showHeader: true,
      showEsc: false,
      cartTitle: 'Shopping Cart',
    };
  },
  computed: {
    loading() {
      return this.$store.getters.isSpinnerActive;
    },
  },
};
</script>
<style>
  .dodtext {
    font-size: 60%;
    font-weight: 300;
    color: gray;
  }
  .panel_area {
    padding-top: 25px;
    padding-bottom: 25px;
    background: #fefefe;
  }

  .modal {
    display: block;
  }

  .invalid-feedback {
    display: block;
  }

  /*Force html and body to the full size of the screen and
        div app and page classes to 100% of the body*/
  html,
  body {
    height: 85vh;
  }

  .app, .page {
    min-height: 85vh !important;
  }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0,0,0,.1) !important;
  }

  @media (max-width: 768px) {
    body {
      padding-top: 100px;
    }
  }

  @media (min-width: 768px) {
    body {
      padding-top: 150px;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease-in-out;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .m0 {
    margin: 0;
  }

  .p0 {
    padding: 0;
  }

  body {
    font-family: "Roboto", sans-serif;
    /*Section Fix*/
  }

  body h1,
  body h2,
  body h3,
  body h4,
  body h5,
  body h6 {
    margin: 0;
  }

  body p {
    font-family: "Roboto", sans-serif;
    line-height: 26px;
    color: #555555;
    margin: 0;
  }

  body section.row,
  body header.row,
  body footer.row {
    margin: 0;
  }

  /*Ancore*/
  a, .btn, button {
    outline: none;
    transition: all 300ms linear 0s;
  }

    a:before, a:after,
    .btn:before,
    .btn:after,
    button:before,
    button:after {
      transition: all 300ms linear 0s;
    }

    a:focus,
    a:hover,
    .btn:focus,
    .btn:hover,
    button:focus,
    button:hover {
      outline: none;
      text-decoration: none;
      transition: all 300ms linear 0s;
    }

  /* Section Title*/
  .section_title {
    text-align: center;
  }

  .section_title h2 {
    font: 700 42px "Roboto", sans-serif;
    color: #222222;
    padding-top: 30px;
    position: relative;
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  .section_title h2:after {
    content: "";
    position: absolute;
    background: #c245e0;
    height: 5px;
    width: 60px;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
  }

  .section_title h4 {
    font: 400 18px "Roboto", sans-serif;
    color: #555555;
    padding-top: 34px;
    white-space: pre-wrap;
  }

  .section_title h5 {
    font: 400 14px "Roboto", sans-serif;
    color: #555555;
    padding-top: 22px;
  }

  .section_title p {
    font: 400 14px/26px "Roboto", sans-serif;
    color: #555555;
    padding-bottom: 30px;
  }

  /* Subtittle */
  .section_subtitle h2 {
    font: 700 36px "Roboto", sans-serif;
    color: #222222;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 20px;
  }

  .section_subtitle h2:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 80px;
    background: #c245e0;
    left: 0;
    bottom: -22px;
  }

  .section_subtitle h5 {
    font: 400 14px "Roboto", sans-serif;
    color: #555555;
    padding-top: 22px;
  }

  /*Linked In*/
  .linked-in {
    margin: 0;
    padding-top: 12px;
  }

  .linked-in a {
    font-size: 14px;
    color: #c245e0;
    padding: 0;
    text-align: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-left: 15px;
  }

  /* Extra background colours */
  .bg-pale-yellow {
    background-color:#f7e7cf !important;
  }
  .bg-daffodil {
    background-color: #ffeb99 !important;
  }
  .bg-faint-grey {
    background-color: #e7e7e7 !important;
  }
  .bg-thunder-grey {
    background-color: #bfbfbf !important;
  }
  .bg-sky-blue {
    background-color: #c7d7e7 !important;
  }
  .bg-baby-blue {
    background-color: #a7c7ff !important;
  }
  .bg-pale-green {
    background-color:#c7e7cf !important;
  }
  .bg-lime {
    background-color: #aaffaa !important;
  }
  .bg-light-orange {
    background-color: #ffb7a7 !important;
  }
</style>
