<template>
    <!-- Footer Area -->
    <footer class="footer_area">
        <div class="container">
            <div class="footer_row row">
                <h5 class="version-header" if="apiVersion">Api Version: {{apiVersion}}</h5>
                <h5 class="version-header">UI Version: {{appVersion}}</h5>
            </div>
        </div>
    </footer>
    <!-- End Footer Area -->
</template>
<script>
import axios from 'axios';
import { apiRoutes } from '@/constants';

import packageInfo from '../../../../package.json';
// import { version } from '../../../../package.json';

export default {
  name: 'page-footer',
  data() {
    return {
      appVersion: packageInfo.version,
      apiVersion: null
    };
  },
  async mounted() {
    this.load();
  },
  methods: {
    load() {
      axios
        .get(`${apiRoutes.app.base}/version`)
        .then((response) => {
          this.apiVersion = response.data.version;
        });
    }
  }
};
</script>
<style>
.version-wrapper {
  align-content: right;
  width: 100vw;
}
.version-header {
  color: grey;
  padding-left: 40px;
}
.footer_area {
  background: rgba(0, 0, 0, 0.1) no-repeat;
  background-image: url(../../../assets/footer.png);
  position: relative;
  background-size: cover;
  background-position: right;
  z-index: 1;

}
.footer_area:after {
    content: "";
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    z-index: -1;
}

.footer_area .footer_row {
    padding-top: 25px;
    padding-bottom: 25px;
    align-content: center;
}

</style>
