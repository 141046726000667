<template>
  <b-container class="page">
    <user-credentials v-if="loginState === 1"
                      @tfa="tfa"
                      @close="close"
                      @forgotLogin="forgotLogin"
                      @goToHome="goToHome" />

    <tfa-selector v-if="loginState === 2"
                  :login="login"
                  :options="tfaMethods"
                  :option.sync="tfaMethod"
                  @close="close"
                  @tfaCode="tfaCode" />

    <tfa-verification v-if="loginState === 3"
                      :login="login"
                      :method="tfaMethod"
                      @close="close"
                       @goToHome="goToHome" />
  </b-container>
</template>
<script>
import FormMethods from '@/mixins/FormMethods.vue';
import UserCredentials from '@/components/App/Login/UserCredentials.vue';
import TfaSelector from '@/components/App/Login/TFASelector.vue';
import TfaVerification from '@/components/App/Login/TFAVerification.vue';

import { clientRoutes } from '../../../constants';

export default {
  name: 'login-form',
  components: {
    UserCredentials,
    TfaSelector,
    TfaVerification,
  },
  mixins: [FormMethods],
  data() {
    return {
      loginState: 1,
      scopeValue: 'loginScope',
      tfaMethods: [],
      tfaMethod: '',
      login: '',
      serverSideErrors: [],
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    tfa(user, methods) {
      this.login = user;
      if (methods.length && methods.length > 0) {
        this.tfaMethod = methods[0].value;
      } else {
        this.tfaMethod = '';
      }
      this.tfaMethods = methods;
      this.loginState = 2;
    },
    tfaCode() {
      this.loginState = 3;
    },
    close() {
      this.loginState = 1;
    },
    forgotLogin() {
      this.loginState = 1;
      this.$router.push({ path: clientRoutes.forgotLogin });
    },
    goToHome() {
      this.loginState = 1;
      const url = clientRoutes.apps.home;
      this.$router.push(url);
    },
  },

};
</script>
