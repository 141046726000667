<template>
  <b-nav-item v-if="isAuthenticated"
              class="nav-auth">
    <b-tooltip target="dewcPortal" title="DEWC Portal" />
    <a id="authNavItemSignOut" @click="logout">
      <i class="fa fa-sign-out" aria-hidden="true"></i>
    </a>
    <b-tooltip target="authNavItemSignOut" title="Log Out" />
  </b-nav-item>
</template>
<script>
import { clientRoutes } from '../../constants';

export default {
  name: 'auth-nav-item',
  computed: {
    appsRoute() {
      return clientRoutes.apps.home;
    },
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    fullName() {
      return `${this.$store.state.auth.fullName}`;
    },
  },
  methods: {

    logout() {
      this.$store.dispatch('logout').then(() => {
        if (this.$route.meta.requiresAuth) {
          this.$router.push(clientRoutes.notAuthorised);
        }
      });
    },
  },
};
</script>
<style scoped>
  .nav-auth{
    min-width: 88px;
  }
</style>
