// Import VueJs libary installed by npm and configured by webpack
import Vue from 'vue';
// Import VueRouter libary installed by npm and configured by webpack
import VueRouter from 'vue-router';
// Import NProgress libary installed by npm and configured by webpack
// Provides a progress spinner to indicate background web activity

// import the route definitions
import routes from './routes';
// import the the Vuex Store
import store from '../store';

import { clientRoutes } from '../constants';

Vue.use(VueRouter);

// import the definde routes into the router
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,

      };
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

// define the functionality to occur before every client-side router redirect
router.beforeEach((to, from, next) => {
  // start the activity indicator
  store.dispatch('startRoute');

  //
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next({ path: clientRoutes.notAuthorised, query: { redirect: to.path } });
    } else if (to.matched.some(
      (route) => route.meta.role && store.getters.isInRole(route.meta.role),
    )) {
      next();
    } else if (!to.matched.some(
      (route) => route.meta.role,
    )) {
      next();
    } else {
      next({ path: clientRoutes.notAuthorised });
    }
  } else if (to.matched.some(
    (route) => route.meta.role
                    && (!store.getters.isAuthenticated
                        || store.getters.isInRole(route.meta.role)),
  )) {
    next();
  } else {
    if (to.matched.some((route) => route.meta.role)) {
      next({ path: clientRoutes.notAuthorised });
    }

    next();
  }
});

// after the redirect
router.afterEach(() => {
  // stop the activity indicator
  store.dispatch('stopRoute');
  store.dispatch('clearLoad');
});

export default router;
