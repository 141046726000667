<template>
  <ValidationProvider :vid="name" :name="label" :rules="validations" v-slot="{ errors, valid }">
    <div class="form-group">
      <label v-if="label" :label-for="id"><b>{{ label }}</b></label>
      <information-tooltip :message="informationTooltip" v-if="informationTooltip" class="pl-2"/>
      <b-form-input :id="id"
                    :name="name"
                    :type="type"
                    :state="state(errors, valid)"
                    :placeholder="placeholder"
                    :value="value"
                    :readonly="readonly"
                    :plaintext="readonly"
                    :step="step"
                    :size="size"
                    @input="updateValue"
                    @change="$emit('change', $event)" />
      <b-form-invalid-feedback>
        <div v-for="(error, index) in errors" :key="index">
          {{ error }}
        </div>
      </b-form-invalid-feedback>
    </div>
  </ValidationProvider>
</template>

<script>

import InformationTooltip from '@/components/Layout/InformationTooltip.vue';
import { ValidationProvider } from 'vee-validate';
import FormInputBaseMethods from '@/mixins/FormInputBaseMethods.vue';

export default {
  name: 'form-input',
  components: {
    InformationTooltip,
    ValidationProvider,
  },
  mixins: [FormInputBaseMethods],
  props: {
    label: {
      type: String,
    },
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    value: {
      type: [String, Boolean, Array, Number],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    validations: {
      type: Object,
    },
    placeholder: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    step: {
      type: String,
    },
    size: {
      type: String,
      default: 'md',
    },
    informationTooltip: {
      type: String,
    },
  },
  methods: {
    updateValue(eventValue) {
      this.$emit('update:value', eventValue);
    },
  },
};
</script>
