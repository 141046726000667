<template>
    <!-- Header_Area -->
    <b-navbar toggleable="md" class="header_navbar_area" id="main_navbar">

        <b-container>
          <div class="col-md-2 p0">
            <b-navbar-toggle class="mx-2" target="nav_collapse"></b-navbar-toggle>
            <b-navbar-brand class="navbar-brand"
                            @click="$router.push({ path: home.url})">
              <img class="brand-img" :src="logo" :alt="home.name">
            </b-navbar-brand>
          </div>
            <div class="col-md-10 p0">
                <b-collapse is-nav id="nav_collapse">
                    <b-navbar-nav class="ml-auto">
                        <b-nav-item @click="$router.push({ path: route.url})"
                                    v-for="route in routes"
                                    :key="route.name">
                            {{route.name}}
                        </b-nav-item>
                        <auth-nav-item v-if="enableLogin"></auth-nav-item>
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-container>

    </b-navbar>

</template>
<script>
import AuthNavItem from '@/components/App/AuthNavItem.vue';

export default {
  name: 'navbar',
  components: {
    AuthNavItem,
  },
  data() {
    return {
      enableLogin: true,
      logo: '/dist/images/DEWC/DEWC Logo Without Text.png',
      home: {
        name: 'HOME',
        url: '/',
      },
      routes: [
      ],
    };
  },
};
</script>
<style>

  @media (max-width: 768px) {
    .header_navbar_area {
      top: 0px;
      position: fixed !important;
    }
  }

  @media (min-width: 768px) {
    .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li.submenu .other_dropdwn {
      margin-right: -122px;
    }
  }

  @media (min-width: 768px) {
    .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li.submenu ul {
      margin-right: -150px;
      display: block;
      transform: rotateX(-90deg);
      transform-origin: top;
    }
  }

  @media (min-width: 768px) {
    .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li:hover.submenu ul {
      transform: rotateX(0deg);
    }

      .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li:hover.submenu ul:before {
        transform: scaleX(1);
      }

      .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li:hover.submenu ul:after {
        transform: scaleX(1);
      }
  }

    /* Header Area */
    .header_navbar_area {
        background: #fff;
        border-radius: 0;
        border: 0;
        margin: 0;
        width: 100%;
        z-index: 9999;
        box-shadow: 0px 0px 20px 0px rgba(21, 47, 95, 0.2);
    }

        .header_navbar_area .show {
            height: 80px;
            border-bottom: 1px solid transparent;
        }

        .header_navbar_area .navbar-header .navbar-brand {
            padding-top: 39px;
        }

    .brand-img {
        height: 30px;
         cursor:pointer;
    }

        .header_navbar_area .navbar-collapse .navbar-nav li a {
            font: 700 20px/75px "Roboto", sans-serif;
            color: #222222;
            text-transform: uppercase;
            padding: 0;
            padding-left: 15px;
        }

            .header_navbar_area .navbar-collapse .navbar-nav li a:hover,
            .header_aera .navbar-collapse .navbar-nav.navbar-right li a:focus {
                color: #c245e0;
            }

    .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li.submenu ul {
        border: none;
        box-shadow: none;
        border-radius: 0px;
        min-width: 190px;
        transition: all 500ms ease-in-out;
        background: #c245e0;
    }

        .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li.submenu ul li a {
            line-height: normal;
            font: 700 14px/normal "Roboto", sans-serif;
            padding: 12px 8px;
            display: block;
        }

    .header_navbar_area .navbar-collapse .navbar-nav{
        background-color: white;
    }

    .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li.submenu ul:before {
        content: "";
        width: 100%;
        height: 5px;
        background: #222222;
        position: absolute;
        top: 0px;
        transform: translateZ(0);
        backface-visibility: hidden;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: all 800ms ease-in-out;
    }

    .header_navbar_area .navbar-collapse .navbar-nav.navbar-right li.submenu ul:after {
        content: "";
        width: 100%;
        height: 5px;
        position: absolute;
        bottom: 0px;
        background: #222222;
        transform: translateZ(0);
        backface-visibility: hidden;
        transform: scaleX(0);
        transform-origin: 100% 50%;
        transition: all 800ms ease-in-out;
    }

    .navbar-item {
        background-color: white;
    }

</style>
