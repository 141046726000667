<script>
export default {
  name: 'form-input-base-methods',
  methods: {
    state(errors, valid) {
      if (errors[0]) {
        return false;
      }
      if (valid) {
        return true;
      }
      return null;
    },
  },
};
</script>
