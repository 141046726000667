<template>
  <b-container class="page">
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onSubmit);">

      <form-input :label="login.label"
                  :name="login.name"
                  :value.sync="login.value"
                  :validations="login.validations"
                  :type="login.type" />
      <information-tooltip :message="passwordAdviceDetail"/>
      <ValidationProvider :vid="password.name" :name="password.name" :rules="password.validations" v-slot="{ errors, valid }">
      <b-form-group :label="password.label"
                    :description="passwordAdvice">
        <b-form-input v-model.trim="password.value"
                      :name="password.name"
                      :state="state(errors, valid)"
                      :type="password.type" />
        <b-form-invalid-feedback>
          <div v-for="(error, index) in errors" :key="index">
          {{ error }}
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      </ValidationProvider>
      <ValidationProvider :vid="confirmPassword.name" :name="confirmPassword.label" rules="required|password:@Password" v-slot="{ errors, valid }">
      <b-form-group :label="confirmPassword.label">
        <b-form-input v-model.trim="confirmPassword.value"
                      :name="confirmPassword.name"
                      :state="state(errors, valid)"
                      :type="confirmPassword.type" />

        <b-form-invalid-feedback>
          <div v-for="(error, index) in errors" :key="index">
          {{ error }}
          </div>
        </b-form-invalid-feedback>
      </b-form-group>
      </ValidationProvider>
      <b-form-group>
        <b-button variant="primary" type="submit"
                  :disabled="loading">Set Password</b-button>
      </b-form-group>
    </b-form>
  </ValidationObserver>
  </b-container>
</template>
<script>
import axios from 'axios';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import FormMethods from '@/mixins/FormMethods.vue';
import FormInput from '@/components/FormControls/FormInput.vue';
import InformationTooltip from '@/components/Layout/InformationTooltip.vue';

import { clientRoutes, apiRoutes, validation } from '../../constants';

export default {
  name: 'register-password-page',
  components: {
    ValidationObserver,
    ValidationProvider,
    FormInput,
    InformationTooltip,
  },
  mixins: [FormMethods],
  data() {
    return {
      login: this.formInputObject('Email', null, 'Email', '', validation.email, 'email'),
      password: this.formInputObject('New Password', null, 'Password', '', validation.passwordStrict, 'password'),
      confirmPassword: this.formInputObject('Confirm New Password', null, 'confirm', '', { }, 'password'),
      registered: false,
      passwordAdvice: 'Password must be between 8 and 100 characters, including an upper case, a lower case, a numeric, '
        + 'and a special character.',
      passwordAdviceDetail: 'Password must be between 8 and 100 characters, including an upper case, a lower case, a numeric, '
        + 'and a special character. Allowed special characters in passwords are: !@#$%^&*()+=',
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    code() {
      return this.$route.params.first + this.$route.params.second;
    },
  },
  methods: {
    resetData() {
      this.registered = true;
      this.login.value = '';
      this.password.value = '';
      this.confirmPassword.value = '';
    },
    onSubmit() {
      const payload = {
        email: this.login.value,
        password: this.password.value,
        confirmPassword: this.confirmPassword.value,
        code: this.code,
      };

      this.$store.commit('startLoading');

      axios
        .post(apiRoutes.account.registerPasswordUrl, payload)
        .then((response) => {
          this.resetData();
          this.$store.commit('finishLoading');

          this.processSuccess(response);

          this.$router.push({ path: clientRoutes.registerTfa });
        })// end then
        .catch((error) => {
          this.processError(error);

          this.$store.commit('finishLoading');
        });// end catch
    }, // end forgotLoginSubmit
  }, // end methods
};
</script>
