// Import VueJs libary installed by npm and configured by webpack
import Vue from 'vue';
// Import BootstrapVue install by npm and configured by webpack
// provide Bootstrap 4 support
// scss support is provided from install packages
import BootstrapVue from 'bootstrap-vue';

import axios from 'axios';

import VueToastr from '@deveodk/vue-toastr';

import { VueReCaptcha } from 'vue-recaptcha-v3';

import vSelect from 'vue-select';

import App from './App.vue';

// Import VueRouter from ./router/index.js
import router from './router';
// Import Store (Vuex) from ./store/index.js
import store from './store';

// import the bootstrap and bootstrap-vue css
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap-vue/dist/bootstrap-vue.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css';
import 'vue-select/dist/vue-select.css';

// helpers
// axios interceptor for JWT refresh tokens
import './helpers/interceptors';

import { httpHeaders, installValidationRules } from './constants';

installValidationRules();

// attach BootstrapVue
Vue.use(BootstrapVue);

Vue.use(VueToastr, { defaultPosition: 'toast-top-right' });

// https://github.com/AurityLab/vue-recaptcha-v3
// prod - '6LegcvcUAAAAAPwjgmOQ1M0dTM6hCGHIxxpBLIOz'
// dev - '6LdvjqoUAAAAAA2bjfvvJZF1fMrDJTJkCIBEmM4g',
Vue.use(VueReCaptcha, {
  siteKey: '6LegcvcUAAAAAPwjgmOQ1M0dTM6hCGHIxxpBLIOz',
  loaderOptions: {
    autoHideBadge: true,
  },
});

Vue.component('v-select', vSelect);

// Setup authorisation upon initialisation using store and axios header

const initialStore = localStorage.getItem('store');

if (initialStore) {
  store.commit('initialise', JSON.parse(initialStore));
  if (store.getters.isAuthenticated) {
    axios.defaults.headers.common[httpHeaders.auth] = `Bearer ${store.state.auth.access_token}`;
  }
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
