<template>
  <b-container class="d-inline p-0">
    <b-badge pill :variant="variant" :class="'information-badge '.concat(' ', badgeClass)"
      v-b-tooltip.hover :title="message">
      <i :class="'fa '.concat(faIconClass)"></i>
    </b-badge>
  </b-container>
</template>
<script>
export default {
  name: 'information-tooltip',
  props: {
    message: {
      type: String,
      required: true,
    },
    badgeClass: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      default: 'info',
    },
    faIconClass: {
      type: String,
      default: 'fa-question',
    },
  },
};
</script>
<style scoped>
 .information-badge {
    font-size: 12px;
    vertical-align: middle;
    padding: 0.2rem 0.4rem 0.2rem 0.4rem;
    margin-top:-0.2rem;
    border: solid 1px #ffffff;
  }
</style>
