<template>
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onSubmit);" class="p-2">
      <form-server-errors :formServerErrors="serverSideErrors" />
      <form-input :label="login.label"
                  :name="login.name"
                  :value.sync="login.value"
                  :validations="login.validations"
                  :type="login.type" />

      <form-input :label="password.label"
                  :name="password.name"
                  :value.sync="password.value"
                  :validations="password.validations"
                  :type="password.type" />

      <b-form-group>
        <b-button-group>
          <b-button variant="primary" type="submit"
                    :disabled="loading">Login</b-button>
          <b-button variant="dark"
                    @click="$emit('forgotLogin')"
                    :disabled="loading">Forgot Login</b-button>
          <b-button variant="light" @click="$emit('close')"
                    :disabled="loading">Cancel</b-button>
        </b-button-group>
      </b-form-group>
    </b-form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import FormInput from '@/components/FormControls/FormInput.vue';
import FormServerErrors from '@/components/FormControls/VeeFormServerErrors.vue';
import FormMethods from '@/mixins/FormMethods.vue';
import { validation } from '../../../constants';

export default {
  name: 'user-credentials',
  components: {
    ValidationObserver,
    FormInput,
    FormServerErrors,
  },
  mixins: [FormMethods],
  data() {
    return {
      scopeValue: 'loginScope',
      login: this.formInputObject('Email', null, 'username', '', validation.email, 'text', 'DEWC Email Address'),
      password: this.formInputObject('Password', null, 'password', '', validation.passwordRelax, 'password', null),
      successMsg: 'Login Successful.',
      serverSideErrors: [],
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    onSubmit() {
      const payload = {
        email: this.login.value,
        password: this.password.value,
      };

      this.$store
        .dispatch('login', payload)
        .then((response) => {
          if (response.data.requiresTfa && response.data.requiresTfa === true) {
            this.$emit('tfa', this.login.value, response.data.tfaMethods);
          } else {
            this.$toastr('success', this.successMsg);
            this.$emit('goToHome');
          }
          this.resetData();
        })
        .catch((error) => {
          this.serverSideErrors = this.processError(error);

          this.processProperties(
            error,
            this.$data,
            false,
            false
          );
        });// end of catch
    },
    resetData() {
      this.login.value = '';
      this.password.value = '';
      this.$refs.observer.reset();
    },
  },
};

</script>
