<template>
    <!-- Top Header_Area -->
    <div class="top_header_area">
    </div>
</template>
<script>

export default {
  name: 'top-navbar',
};
</script>
<style scoped>
  /* Top header */
  /***********************************************
    *   Top Header
    */
  .top_header_area {
    background: black;
    z-index: 10000;
    width: 100%;
    height: 40px;
  }

    @media (max-width: 768px) {
        .top_header_area{
            visibility:hidden;
        }
    }

</style>
