<template>

    <ValidationObserver ref="observer" v-slot="{ passes }">
    <b-form @submit.prevent="passes(onSubmit);" class="p-2">
    <form-drop-list :label="tfaMethods.label"
                :name="tfaMethods.name"
                :value="option"
                :validations="tfaMethods.validations"
                :options="options"
                @change="$emit('update:option', $event)" />
                <b-form-group>
        <b-button-group>
          <b-button variant="primary" type="submit"
                    :disabled="loading">Get Code</b-button>
          <b-button variant="light" @click="$emit('close')"
                    :disabled="loading">Cancel</b-button>
        </b-button-group>
      </b-form-group>
      </b-form>
  </ValidationObserver>

</template>
<script>

import { ValidationObserver } from 'vee-validate';
import FormDropList from '@/components/FormControls/FormDropList.vue';
import FormMethods from '@/mixins/FormMethods.vue';
import { validation } from '../../../constants';

export default {
  name: 'tfa-selector',
  components: {
    ValidationObserver,
    FormDropList,
  },
  mixins: [FormMethods],
  props: {
    login: {
      type: String,
    },
    options: {
      type: Array,
    },
    option: {
      type: String,
    },
  },
  data() {
    return {
      tfaMethods: this.formListObject('Select a method to receive verification code:', null, 'tfaMethod', '', validation.required, []),
    };
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    onSubmit() {
      const payload = {
        email: this.login,
        method: this.option,
      };
      this.$store
        .dispatch('tfaGetCode', payload)
        .then((response) => {
          this.$toastr('success', response.data);
          this.$emit('tfaCode');
        })
        .catch((error) => {
          this.processError(error);
        });// end of catch
    },
  },
};

</script>
