<template>
    <b-container class="page">
        <b-row class="app-category" v-for="category in userApps" :key="category.title" >
            <b-col md="12"><h2>{{category.title}}</h2></b-col>
            <b-col v-for="app in category.apps" :key="app.title" lg="3">
                <b-card class="icon-feature"
                        footer-bg-variant="secondary"
                        footer-text-variant="white"
                        :footer="app.title"
                        @click="$router.push({ path: app.url});">
                        <b-card-body class="icon-feature-body">
                            <i :class="app.icon" aria-hidden="true"></i>
                        </b-card-body>

                </b-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>

import axios from 'axios';
import { apiRoutes } from '../../../constants';

export default {
  vname: 'apps-home',
  data() {
    return {
      userApps: [],
    };
  },
  // lifecycle hook
  beforeCreate() {
    // load users
    axios
      .get(apiRoutes.app.base)
      .then((response) => {
        // response = Object.assign(new AppCategory(), response);
        this.userApps = response.data;
      })// end then
      .catch(() => {
        // alert('Error Loading Users from Server.');
      });// end catch
  },
};
</script>

<!--script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

import axios from 'axios';
import AppCategory from '@/models/Classes/app-category';
import { apiRoutes } from '../../../constants';

@Component({})

export default class AppsHomePage extends Vue {
    // initial data
    userApps: AppCategory[] = [];

    // lifecycle hook
    beforeCreate() {
    // load users
      axios
        .get<AppCategory[]>(apiRoutes.app.base)
        .then((response) => {
        // response = Object.assign(new AppCategory(), response);
          this.userApps = response.data;
        })// end then
        .catch(() => {
        // alert('Error Loading Users from Server.');
        });// end catch
    }
}
</script-->
<style scoped>

    .app-category {
        margin-bottom: 50px;
        margin-top: 50px;
        border-bottom: 1px solid #c245e0;
    }

  .icon-feature {
    height: 75%;
    text-align: center;
    margin:10px;
  }
  .icon-feature-body{
      padding-bottom: 2.0rem;
  }

    .icon-feature-body i {
        font-size: 22px;
        color: #fefefe;
        line-height: 35px;
        width: 50%;
        text-align: center;
        background: #333333;
        border: 5px solid #949393;
    }

    .icon-feature-body i:hover {
        font-size: 28px;
        color: #c245e0;
        line-height: 35px;
        width: 50%;
        text-align: center;
        background: #333333;
        border: 5px solid #c245e0;
    }

</style>
